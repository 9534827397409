/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState, useRef } from "react";
import classNames from "classnames";
import { Image } from "@atoms";
import MiniSearch from "minisearch";

const ArticleList = () => {
  const [articles, setArticles] = useState([]);
  const [sort, setSort] = useState("title");
  const [topicFilter, setTopicFilter] = useState();
  const [categoryFilter, setCategoryFilter] = useState();
  const [loading, setLoading] = useState(false);
  const [ascending, setAscending] = useState(false);
  const [query, setQuery] = useState("");
  const [results, setResults] = useState(false);
  const miniSearch = useRef();

  useEffect(() => {
    miniSearch.current = new MiniSearch({
      prefix: true,
      fuzzy: 0.2,
      fields: ["title", "metaDescription"], // fields to index for full-text search
      storeFields: [
        "title",
        "metaDescription",
        "image",
        "topic",
        "category",
        "postDate",
        "dateUpdated",
        "url",
        "id",
        "slug",
        "uid",
      ], // fields to return with search results
    });
    miniSearch.current.addAll(articles);
  }, [articles]);

  useEffect(() => {
    if (query.length && miniSearch.current) {
      const res = miniSearch.current.search(query);
      setResults(res);
    } else {
      setResults(false);
    }
  }, [query]);

  const getArticles = () => {
    setLoading(true);
    fetch("/api/get-articles")
      .then(res => res.json())
      .then(res => {
        setArticles(res);
        setLoading(false);
      });
  };

  // get the articles
  useEffect(() => {
    getArticles();
  }, []);

  const adjustSort = param => {
    setSort(s => {
      if (s === param) {
        setAscending(a => !a);
      } else {
        setAscending(true);
      }
      return param;
    });
  };

  return (
    <div className="p-6 flex flex-col h-screen w-screen overflow-hidden">
      <div className="mb-3 flex items-center gap-3">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          className="w-6 block transition duration-200 transform"
        >
          <title>Newspaper</title>
          <rect
            x="96"
            y="112"
            width="96"
            height="96"
            rx="16"
            ry="16"
            fill="none"
          />
          <path
            className="fill-red"
            d="M468 112h-52v304a32 32 0 0032 32 32 32 0 0032-32V124a12 12 0 00-12-12z"
          />
          <path
            className="fill-white"
            d="M431.15 477.75A64.11 64.11 0 01384 416V44a12 12 0 00-12-12H44a12 12 0 00-12 12v380a56 56 0 0056 56h342.85a1.14 1.14 0 00.3-2.25zM96 208v-96h96v96zm224 192H96v-32h224zm0-64H96v-32h224zm0-64H96v-32h224zm0-64h-96v-32h96zm0-64h-96v-32h96z"
          />
        </svg>
        <h1 className="font-bold text-sm">Article List</h1>
        <div className="ml-auto w-full max-w-[20rem]">
          <div className="flex items-center text-xs justify-center w-full flex-grow">
            <label className="mr-2">search:</label>
            <input
              name="url"
              onChange={e => {
                setQuery(e.target.value);
              }}
              value={query}
              className="w-full border-b border-red border-opacity-30 bg-transparent py-1 leading-0 px-0 focus:border-opacity-100"
            />
          </div>
        </div>
      </div>
      <ul className="overflow-auto flex-grow leading-tight text-2xs flex flex-col border-red border">
        <li
          className={classNames(
            "sticky bg-black z-10 top-0 left-0 right-0 p-2 w-full font-bold border-b border-red border-opacity-60 grid grid-cols-12 gap-8"
          )}
        >
          <button
            type="button"
            onClick={() => adjustSort("title")}
            className="flex col-span-4 items-center"
          >
            <span className="mr-1">title</span>{" "}
            {sort === "title" && (
              <span
                className={classNames("transform transition duration-200", {
                  "rotate-180": ascending,
                })}
              >
                ▾
              </span>
            )}
          </button>
          <button
            type="button"
            onClick={() => adjustSort("postDate")}
            className="flex items-center"
          >
            <span className="mr-1">Posted</span>{" "}
            {sort === "postDate" && (
              <span
                className={classNames("transform transition duration-200", {
                  "rotate-180": ascending,
                })}
              >
                ▾
              </span>
            )}
          </button>
          <button
            type="button"
            onClick={() => adjustSort("dateUpdated")}
            className="flex items-center"
          >
            <span className="mr-1">Updated</span>{" "}
            {sort === "dateUpdated" && (
              <span
                className={classNames("transform transition duration-200", {
                  "rotate-180": ascending,
                })}
              >
                ▾
              </span>
            )}
          </button>
          <button
            type="button"
            onClick={() => adjustSort("topic")}
            className="flex items-center"
          >
            <span className="mr-1">topic</span>{" "}
            {sort === "topic" && (
              <span
                className={classNames("transform transition duration-200", {
                  "rotate-180": ascending,
                })}
              >
                ▾
              </span>
            )}
          </button>
          <button
            type="button"
            onClick={() => setSort("category")}
            className="flex items-center"
          >
            <span className="mr-1">category</span>{" "}
            {sort === "category" && (
              <span
                className={classNames("transform transition duration-200", {
                  "rotate-180": ascending,
                })}
              >
                ▾
              </span>
            )}
          </button>
          <span className="flex col-span-3 items-center">desc</span>
          <span className="flex items-center justify-end gap-6">
            <button
              onClick={getArticles}
              type="button"
              className={classNames(
                "text-red transition duration-200 hover:text-white",
                { "animate-spin opacity-50 pointer-events-none": loading }
              )}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-4 h-4"
                viewBox="0 0 512 512"
                fill="currentColor"
              >
                <path d="M256 48C141.31 48 48 141.32 48 256c0 114.86 93.14 208 208 208 114.69 0 208-93.31 208-208 0-114.87-93.13-208-208-208zm94 219a94 94 0 11-94-94h4.21l-24-24L256 129.2l59.8 59.8-59.8 59.8-19.8-19.8 27.92-27.92c-2.4-.08-5.12-.08-8.12-.08a66 66 0 1066 66v-14h28z" />
              </svg>
            </button>
          </span>
        </li>
        {(results || articles)
          .filter(
            a =>
              (!topicFilter || a.topic === topicFilter) &&
              (!categoryFilter || a.category === categoryFilter)
          )
          .sort((a, b) =>
            ascending
              ? a[sort]?.toLowerCase().localeCompare(b[sort]?.toLowerCase())
              : b[sort]?.toLowerCase().localeCompare(a[sort]?.toLowerCase())
          )
          .map((page, i) => (
            <li
              key={page.uid}
              className={classNames(
                "p-2 w-full border-b border-red pb-6 border-opacity-30 grid grid-cols-12 gap-8"
              )}
            >
              <span>
                <Image image={page.image} />
              </span>
              <span className="col-span-3 overflow-hidden text-base">
                {page.title}
              </span>
              <span className="overflow-hidden">
                {new Date(page.postDate).toLocaleDateString()}
              </span>
              <span className="overflow-hidden">
                {new Date(page.dateUpdated).toLocaleDateString()}
              </span>
              <span
                className={classNames(
                  "flex transition duration-200 hover:text-red",
                  {
                    "text-red": !page?.topic?.length,
                  }
                )}
              >
                <button
                  type="button"
                  className="item-start text-left flex"
                  onClick={() =>
                    setTopicFilter(s => (s === page.topic ? null : page.topic))
                  }
                >
                  {page?.topic?.length ? page.topic : "NONE"}
                </button>
              </span>
              <span className="flex">
                <button
                  type="button"
                  className="item-start text-left flex"
                  onClick={() =>
                    setCategoryFilter(s =>
                      s === page.category ? null : page.category
                    )
                  }
                >
                  {page.category}
                </button>
              </span>
              <span className="col-span-3 overflow-hidden">
                {page.metaDescription}
              </span>
              <span className="flex gap-4">
                <a
                  title="edit"
                  href={`https://cms.designbycosmic.com/admin/entries/article/${page.id}-${page.slug}`}
                  className="h-4 inline-flex gap-1 items-center text-3xs font-bold text-red transition duration-200 hover:text-white"
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg
                    viewBox="0 0 512 512"
                    className="h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                  >
                    <path d="m464.37 49.2a22.07 22.07 0 0 0 -31.88-.76l-18.31 18.25 31.18 31.1 18-17.91a22.16 22.16 0 0 0 1.01-30.68zm-211.61 286.8h-76.76v-76.76l9.4-9.38 138.14-137.86h-275.54v352h352v-275.54l-137.86 138.14zm147.24-192.84 32.79-32.86-31.09-31.09-32.85 32.79h31.15z" />
                    <path d="m208 304h31.49l160.51-160.84v-31.16h-31.15l-160.85 160.51z" />
                  </svg>
                  <span>edit</span>
                </a>
                <a
                  title="view page"
                  href={page.url}
                  className="h-4 inline-flex gap-1 items-center text-3xs font-bold text-red transition duration-200 hover:text-white"
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                    <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
                  </svg>
                  <span>view</span>
                </a>
              </span>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default ArticleList;
